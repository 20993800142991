import React from 'react';

function Column1() {
    return (
        <div className="column1">
            <div className="vertical-text">Alessandro Piccin</div>
        </div>
    );
}

export default Column1;
