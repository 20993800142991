import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Import Router components
import './App.css'; // Import your CSS file
import Column1 from './Column1';
import Column2 from './Column2';
import Column3 from './Column3';
import Column4 from './Column4';
import DetailedInfoPage from './DetailedInfoPage'; // Import the DetailedInfoPage component

function App() {
    return (
        <Router>
            <div className="container">
                <Column1 />
                <Column2 />
                <Column3 />
                <Column4 />
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                    <defs>
                        <filter id="round">
                            <feGaussianBlur in="SourceGraphic" stdDeviation="5" result="blur" />    
                            <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="goo" />
                            <feComposite in="SourceGraphic" in2="goo" operator="atop"/>
                        </filter>
                    </defs>
                </svg>
            </div>

            {/* Define routes */}
            <Routes>
                <Route path="/" element={<Column1 />} />
                <Route path="/column2" element={<Column2 />} />
                <Route path="/column3" element={<Column3 />} />
                <Route path="/column4" element={<Column4 />} />
                <Route path="/detailed/:topic" element={<DetailedInfoPage />} />
            </Routes>
        </Router>
    );
}

export default App;
