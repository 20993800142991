import React from 'react';
import { useNavigate } from 'react-router-dom';
import './App.css'; // Ensure this path is correct

const Scheda = ({ title, description, backgroundUrl, url }) => {
    const navigate = useNavigate(); // Use navigate for programmatic navigation

    const handleButtonClick = () => {
        navigate(url); // Navigate to the specified URL
    };

    return (
        <div className="scheda" style={{ backgroundImage: `url(${backgroundUrl})` }}>
            <div className="title3">{title}</div>
            <div className="description">{description}</div>
            <button className="scheda-button" onClick={handleButtonClick}>More Info</button>
        </div>
    );
};

export default Scheda;
