import React from 'react';
import { useParams } from 'react-router-dom';

function DetailedInfoPage() {
    const { topic } = useParams(); // Extract the topic from the URL

    // Mock data or fetch data based on `topic`
    // This can be dynamic depending on your setup
    const getData = (topic) => {
        // Fetch or determine the data based on `topic`
        // For now, just return a mock message
        return `Details for ${topic}`;
    };

    return (
        <div className="detailed-info-page">
            <h1>{getData(topic)}</h1>
            {/* You can add more detailed information here */}
        </div>
    );
}

export default DetailedInfoPage;
