import React from 'react';

function Column4() {
    return (
        <div className="column4">
            <div className="vertical-text">Projects</div>
        </div>
    );
}

export default Column4;
