import React, { useState, useEffect, useRef } from 'react';
import Scheda from './Scheda'; // Ensure this path is correct

// Utility function to shuffle an array
const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]]; // Swap elements
    }
    return array;
};

function Column3() {
    const [items, setItems] = useState([]);
    const columnRef = useRef(null);
    const [isHovered, setIsHovered] = useState(false);

    // Fetch JSON data from the public folder
    useEffect(() => {
        fetch('data.json', {
            mode: 'cors',  // This is usually the default, but explicitly adding it
            method: 'GET', // Make sure the method matches your data fetching needs
            headers: {
                'Content-Type': 'application/json' // Ensures the server understands JSON
            }
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to fetch schede data');
                }
                return response.json();
            })
            .then((data) => {
                // Shuffle items and set initial state
                const shuffledItems = shuffleArray([...data]);
                setItems([...shuffledItems, ...shuffledItems]); // Duplicate items for scrolling effect
            })
            .catch((error) => console.error('Error fetching schede data:', error));
    }, []);

    // Handler to check the scroll position and append more items when needed
    const handleScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = columnRef.current;
        if (scrollTop + clientHeight >= scrollHeight - 10) {
            // Duplicate items when near the bottom but preserve initial order
            setItems((prevItems) => [...prevItems, ...items]);
        }
    };

    useEffect(() => {
        const column = columnRef.current;
        if (column) {
            column.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (column) {
                column.removeEventListener('scroll', handleScroll);
            }
        };
    }, [items]);

    // Auto-scroll functionality
    useEffect(() => {
        const column = columnRef.current;
        let scrollInterval;

        if (column && !isHovered) {
            scrollInterval = setInterval(() => {
                if (column.scrollTop + column.clientHeight >= column.scrollHeight) {
                    // Reset scroll to the top when reaching the bottom
                    column.scrollTop = 0;
                } else {
                    // Scroll down by 1 pixel for slower effect
                    column.scrollTop += 1;
                }
            }, 100); // Increase interval timing for slower scroll effect
        }

        // Cleanup interval on component unmount or when hover state changes
        return () => clearInterval(scrollInterval);
    }, [isHovered]);

    // Event handlers to manage hover state
    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);

    return (
        <div
            className="column3"
            ref={columnRef}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {items.map((item, index) => (
                <div key={index} className="scheda-wrapper">
                    <Scheda
                        title={item.title}
                        description={item.description}
                        backgroundUrl={item.backgroundUrl}
                        url={item.url} // Pass the URL prop
                    />
                </div>
            ))}
        </div>
    );
}

export default Column3;
